<template>
  <div>
      <div class="mobile_view" style="justify-content: center;">
      <div class="container position-relative" style="padding:0px !important;margin: 0;max-width: 100% !important;">
        <div class="row shoe_box">
          <div id="sidebar" style="margin-left: -1px;position:relative;background-color: white;padding-bottom:3px">
            <div  id="scrolldiv" style="position:relative;height:91vh;overflow-y:scroll;max-height:100%">
              <div v-if="displayMode==1">
                <div @click="changePage(currPage,-1)" v-if="currPage!=1" style="cursor:pointer;padding-top:3px;padding-bottom:3px;background-color: #F43B55;color:white" class="hh"> &lt;&lt; Page {{currPage-1}}</div>
                <div class="hh" v-for="(i,index) in videoDetailsList" :key="index">
                    <img @click="playVideo(index,i)" class="playv" width="35px" height="35px" src=".././assets/play-icon2.png">
                    <div v-if="i.thumbnailUrls" class = "mousehvr" @click="playVideo(index,i)" style="margin: 2px; padding-right: 0px;padding-left: 0px;margin-right: 0;margin-left: 0;padding-bottom: 10px;">
                      <img width="115px" v-if="i.thumbnailUrls" :src="i.thumbnailUrls" style="height: 130px;width: 100%;object-fit: cover;padding:none">
                      <div style="font-size: 12px;font-family: sans-serif;">{{i.title}}</div>
                    </div>
                </div>
                <div @click="changePage(currPage,1)" style="cursor:pointer;padding-top:3px;padding-bottom:3px;background-color: #F43B55;color:white" class="hh">Page {{currPage+1}} >></div>
              </div>
              <div v-if="displayMode==2">
                <div class="hh" v-for="(i,index) in productDetailsList" :key="index">
                    <div v-if="i.images[0]" class = "mousehvr" @click="showProduct(i)" style="margin: 2px; padding-right: 0px;padding-left: 0px;margin-right: 0;margin-left: 0;padding-bottom: 15px;cursor:pointer">
                      <img v-if="i.images[0]" :src="i.images[0]" style="height: 130px;width: 100%;padding:none">
                      <div style="font-size: 12px;font-family: sans-serif;">{{i.name}}</div>
                    </div>
                </div>
              </div>
            </div>
            <button v-if="displayMode==2" @click="openGiftPromo()" class="buy-btn">BUY</button>
          </div>
        </div>
          <div  class="row video_box">
          <div class="col-md-12 p-0">
            <div class="videoDiv" id="videobox">
              <div class="videoSection" >
                <video  playsinline @click="displayMode=0" preload="auto" id="video-elem" muted loop autoplay>
                  <source :src="currentVideoObject.videoUrls.MP4[0]" v-if="currentVideoObject.videoUrls">
                  Your browser does not support HTML5 video.
                </video>
                <div class="row">
                    <div>
                      <i class="fa fa-eye" width="100px" height="80px" aria-hidden="true"></i>
                      <div class="viewcount">{{viewsCurrVideo}}</div>
                    </div>
                    <div><img width="60px" height="60px" class="brandlogo" :src="brandurl"></div>
                    <div><img width="80px" height="70px" class="wtvlogo" src=".././assets/icons/wtv-icon.svg"></div>
                </div>
                <vodal :width=250 :height="380" :show="showPromoCodeModal" animation="rotate" @hide="showPromoCodeModal = false">
                    <div style="margin-top:15px">
                        <h4 style="color:#F43B55 !important;padding-top:0px;font-weight:bold">Exclusive Offer</h4>
                        <br>
                        <span style="font-size:20px;margin-top:6px;font-weight:bold">Avail Upto {{promocodeDiscountPercent}}% discount</span>
                        <br><br>
                        <span style="font-size:17px;font-weight:bold">{{promocodeText}}</span>
                        <br><br>
                        <p style="font-size:17px;font-weight:bold">Proceed to the brand website by clicking the button below</p>
                        <button  @click="redirectBuy()" style="width:170px" class="buy-btn">BUY</button>
                    </div>
                </vodal>
                <vodal class="mymodal" :width=220 :height="400" :show="showProductObj" animation="rotate" @hide="showProductObj = false;product={}">
                    <div style="color:white;background-color:#999;padding:5px">{{brandname}}</div>
                    <div style="padding:10px;max-height:350px;overflow-y:scroll">
                      <img width="170" height="170" :src="product.url">
                      <br><br><br>
                      <span style="font-size:15px">{{product.title}}</span>
                      <br><br>
                      <span style="font-size:17px;font-weight:bold">Rs {{product.price}}</span>
                    </div>
                </vodal>
                <vodal class="mymodal" :width=200 :height="215" :show="isSharePopupVisible" animation="rotate" @hide="isSharePopupVisible = false;">
                    <div style="color:white;background-color:#999;padding:5px">Share On :</div>
                    <br>
                    <div class="row center-it">
                      <div class="col-2 sm-icons">
                        <facebook  :url="pageurl" :title="currentVideoObject.title" scale="3"></facebook>
                      </div>
                      <div class="col-1 sm-icons">
                      </div>
                      <div class="col-2 sm-icons">
                        <twitter :url="pageurl" :title="currentVideoObject.title" scale="3"></twitter>
                      </div>
                    </div>
                    <br>
                    <div class="row center-it">
                      <div class="col-2 sm-icons">
                        <telegram :url="pageurl" :title="currentVideoObject.title" scale="3"></telegram>
                      </div>
                      <div class="col-1 sm-icons">
                      </div>
                      <div class="col-2 sm-icons"><!--<a :href="'https://api.whatsapp.com/send/?phone&text='+encodeURIComponent(pageurl)" target="_blank" data-action="share/whatsapp/share"><whats-app :url="pageurl" class="disabledbutton"  :title="currentVideoObject.title" scale="3"></whats-app></a>--> 
                        <whats-app :url="pageurl" :title="currentVideoObject.title" scale="3"></whats-app>
                      </div>
                    </div>
                </vodal>
                <div v-show="currentVideoObject.videoUrls">
                  <div v-if="!isGiftIconClicked" @click="openGiftPromo()"><img width="35px" height="35px" class="gift" src=".././assets/icons/9.svg"></div>
                  <div v-if="isGiftIconClicked" @click="openGiftPromo()"><img width="35px" height="35px" class="gift" src=".././assets/icons/9.svg"></div>
                  <div @click="fullScreenVideo()"><img width="35px" height="35px" class="expand" src=".././assets/icons/expand.svg"></div>
                  <div v-if="isVideoMuted" @click="playTheVideo()"><img width="35px" height="35px" class="speak" src=".././assets/icons/volume_off.svg"></div>
                  <div v-if="!isVideoMuted" @click="pauseTheVideo()"><img width="35px" height="35px" class="mute" src=".././assets/icons/volume_on.svg"></div>
                  <div @click="displayMode=1" v-if="displayMode!=1"><img class="camera" width="35px" height="35px" src=".././assets/icons/7.svg"></div>
                  <div @click="displayMode=1" v-if="displayMode==1"><img class="camera" width="35px" height="35px" src=".././assets/icons/8.svg"></div>
                  <div @click="fetchProductDetails" v-if="displayMode!=2"><img class="cart" width="35px" height="35px" src=".././assets/icons/6.svg"></div>
                  <div @click="fetchProductDetails" v-if="displayMode==2"><img class="cart" width="35px" height="35px" src=".././assets/icons/5.svg"></div>
                  <div><img @click="isSharePopupVisible=true;displayMode=0;" class="share" width="35px" height="35px" src=".././assets/icons/3.svg">
                  </div>
                  <div @click="likeCurrVideo()" v-if="!isVideoLiked"><img class="like" width="35px" height="35px" src=".././assets/icons/1.svg"></div>
                  <div v-if="isVideoLiked"><img class="like" width="35px" height="35px" src=".././assets/icons/2.svg"></div>

                  <div v-if="displayMode==0" @click="followUser()" class="userprofile">
                    <img class="userprofileicon" width="31px" height="31px" src=".././assets/profile.png">{{videoUserName}}<button style="width:85px;height:30px;background-color:white;color:#F43B55;font-size:16px;border-radius:15px;margin-left:10px">{{followStatus}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>  
      </div>
    </div>
  </div>
</template>

<script>
try
{
  window.scrollTo(0, 0);
}
catch(e)
{
  console.log(e);
}

import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSocialSharing from 'vue-social-sharing'
import Vodal from 'vodal';
import "vodal/common.css";
import "vodal/rotate.css";
import {
  Facebook,
  Twitter,
  WhatsApp,
  Telegram
} from "vue-socialmedia-share";
Vue.component(Vodal.name, Vodal);
Vue.use(VueAxios, axios)
Vue.use(VueSocialSharing);
import VueSimpleAlert from "vue-simple-alert";

Vue.use(VueSimpleAlert);
var hidden, visibilityChange; 
try{
  if (typeof document.hidden !== "undefined") {  
    hidden = "hidden";
    visibilityChange = "visibilitychange";
  } else if (typeof document.msHidden !== "undefined") {
    hidden = "msHidden";
    visibilityChange = "msvisibilitychange";
  } else if (typeof document.webkitHidden !== "undefined") {
    hidden = "webkitHidden";
    visibilityChange = "webkitvisibilitychange";
  }
}
catch(e)
{
  console.log(e)
}

export default {
  name: 'Video',
  props: {
  },
  components: {
    Facebook,
    Twitter,
    Telegram,
    WhatsApp
  },
  data() {
      return {
        videoDetailsList:[],
        productDetailsList:[],
        currentVideoObject:{},
        isVideoMuted:true,
        brandLogoURL:'',
        displayMode:0,
        myloader:'',
        isVideoLiked:false,
        isGiftIconClicked:false,
        viewsCurrVideo:0,
        showPromoCodeModal:false,
        promocodeDiscountPercent:'',
        promocodeText:'',
        vendorUrl:'',
        brandname:'',
        showProductObj:false,
        product:{
          url:'',
          price:'',
          title:''
        },
        isSharePopupVisible:false,
        videoUserName:'',
        followStatus:'Follow',
        currPage:1
      }
  },
  watch:{
    'videoPlayList':function(val)
    {
      console.log(val);
    },
    'displayMode':function(val)
    {
      console.log(val);
      document.getElementById('sidebar').style.height = (document.getElementById('videobox').clientHeight)/1.0233 + 'px';
      if(val==1)
      {
        document.getElementById('scrolldiv').style.height = (document.getElementById('videobox').clientHeight)/1.0233 + 'px';
      }
      else
      {
        document.getElementById('scrolldiv').style.height = '91vh';
      }
    }
  },
  computed: {
    pageurl:function()
    {
      var url = window.location.origin+ '/';
      if(sessionStorage.getItem('userId'))
      {
        url = url + this.$route.params.brand + '/' + this.currentVideoObject.video_id + '?uid=' + sessionStorage.getItem('userId');
      }
      else
      {
        url = url + this.$route.params.brand + '/' + this.currentVideoObject.video_id;
      }
      return url;
    },
    brandurl:function()
    {
      var url = window.location.origin + '/assets/logo/logo.svg';
      return url;
    },
    videoPlayList:function()
    {
      if(this.videoDetailsList.length>0)
      {
        var tempList = [];
        for(var i=0;i<this.videoDetailsList.length;i++)
        {
          if(this.videoDetailsList[i].id != this.currentVideoObject.id)
          {
            tempList.push(this.videoDetailsList[i]);
          }
        }
        return tempList;
      }
      else
      {
        var x = [];
        return x;
      }
    }
  },
  mounted()
  {
    try
    {
      document.getElementById('sidebar').style.height = (document.getElementById('videobox').clientHeight)/1.0233 + 'px';
      window.scrollTo(0, 0);
    }
    catch(e)
    {
      console.log(e);
    }
    document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
  },
  created()
  {
    if(sessionStorage.getItem("accessToken")=='' || sessionStorage.getItem("accessToken")==null)
    { 
      var brand = this.$route.params.brand;
      if(brand.length>0)
      {
        this.$router.push({ path: '/'+ this.$route.params.brand});
      }
      else
      {
        console.log('sayonaraa33333')
        this.$router.push({ path: '/404notfound'}); 
      }
    }
    else
    {
      var brandname = this.$route.params.brand;
      console.log(brandname);
      this.getBrandID();
    }
    window.addEventListener("resize", this.myEventHandler);
    screen.orientation.lock("portrait-primary");
  },
  destroyed() {
  window.removeEventListener("resize", this.myEventHandler);
  },
  methods:{
    changePage(currPage,pageChange)
    {
      this.getVideoFeedForBrandPageChange(currPage,this.$route.params.brand,pageChange);
    },
    handleVisibilityChange()
    {
      var videoElement = document.getElementById("video-elem");
      if(document[hidden] && videoElement.style.display != 'none')
      {
        console.log('aaa')
        videoElement.pause();
      }
      else
      {
        console.log('bbb')
        videoElement.play();
      }
    },
    recordVideoShare(uid,vId)
    {
        const myheaders = {
          'deviceId':sessionStorage.getItem('deviceId'),
          'userId':sessionStorage.getItem('userId'),
          'accessToken':sessionStorage.getItem("accessToken"),
        }
        axios.post(`https://api.wakaw.live/v1/api/analytics/videoshare/view`,
        {
          shared_by_user_id:uid,
          videoId:vId
        },{headers: myheaders})
        .then(response => {
          console.log(response)
        });
    },
    setFollowStatus()
    {
        const myheaders = {
          'deviceId':sessionStorage.getItem('deviceId'),
          'userId':sessionStorage.getItem('userId'),
          'accessToken':sessionStorage.getItem("accessToken"),
        }
        axios.get(`https://api.wakaw.live/v1/api/follow/status?followeeId=` + this.currentVideoObject.user_id,
        {
            headers:myheaders 
        }).then(response => {
          if(response.data.data.status)
          {
            this.followStatus = 'Following';
          }
        })
    },
    followUser()
    {
        const myheaders = {
          'deviceId':sessionStorage.getItem('deviceId'),
          'userId':sessionStorage.getItem('userId'),
          'accessToken':sessionStorage.getItem("accessToken"),
        }
        axios.post(`https://api.wakaw.live/v1/api/follow`,
        {
          following_id:this.currentVideoObject.user_id
        },{headers: myheaders})
        .then(response => {
          //console.log(response.data.status)
          if(response.data.status == "success")
          {
            this.followStatus = 'Following'
          }
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
    showProduct(item)
    {
      console.log(item);
      this.showProductObj = true;
      this.displayMode = 0;
      this.product.url = item.images[0];
      this.product.title = item.name;
      this.product.price = item.variantMrp;
    },
    getVideoAnalytics()
    {
        const myheaders = {
          'deviceId':sessionStorage.getItem('deviceId'),
          'userId':sessionStorage.getItem('userId'),
          'accessToken':sessionStorage.getItem("accessToken"),
        }
        axios.post(`https://api.wakaw.live/v1/api/analytics/video/`+this.currentVideoObject.video_id+'/view',
        {
        },{headers: myheaders})
        .then(response => {
          console.log(response);
          axios.get(`https://api.wakaw.live/v1/api/analytics/video/` + this.currentVideoObject.video_id,
          {
            headers:myheaders 
          }).then(response => {
            console.log(response.data.data);
            var info = response.data.data;
            if(info.viewsCount)
            {
              this.viewsCurrVideo = info.viewsCount;
            }
            if(info.UserStatus.likes==true || info.UserStatus.likes=='true')
            {
              this.isVideoLiked = true;
            }
          })
          .catch(e => {
            this.errors.push(e)
          })
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
    likeCurrVideo()
    {
        const myheaders = {
          'deviceId':sessionStorage.getItem('deviceId'),
          'userId':sessionStorage.getItem('userId'),
          'accessToken':sessionStorage.getItem("accessToken"),
        }
        axios.post(`https://api.wakaw.live/v1/api/analytics/video/`+this.currentVideoObject.video_id+'/view',
        {
        },{headers: myheaders})
        .then(response => {
          console.log(response);
          axios.post(`https://api.wakaw.live/v1/api/analytics/video/`+this.currentVideoObject.video_id+'/like',
          {
          },{headers: myheaders})
          .then(response => {
            console.log(response);
            this.isVideoLiked = true;
          })
          .catch(e => {
            this.errors.push(e)
          })
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
    openGiftPromo()
    {
      this.isGiftIconClicked = true;
      this.displayMode = 0;
      const myheaders = {
          'deviceId':sessionStorage.getItem('deviceId'),
          'userId':sessionStorage.getItem('userId'),
          'accessToken':sessionStorage.getItem("accessToken"),
        }
        axios.get(`https://api.wakaw.live/v1/promos/NEWWAKAW10`,
        {
          headers: myheaders
        })
        .then(response => {
          console.log(response);
          this.promocodeDiscountPercent = response.data.data.discount;
          this.promocodeText = response.data.data.description;
          this.showPromoCodeModal = true;
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
    startLoader() {
			this.myloader = this.$loading.show({ canCancel: false, color:'#34749B' });
		},
		stopLoader() {
			this.myloader.hide();
    },
    redirectBuy()
    {
      console.log(this.vendorUrl);
      this.vendorUrl = "https://" + this.vendorUrl;
      window.open(this.vendorUrl, '_newtab');
    },
    fetchProductDetailsInitial()
    {
      console.log('holaxx');
      const myheaders = {
        'deviceId':sessionStorage.getItem('deviceId'),
        'userId':sessionStorage.getItem('userId'),
        'accessToken':sessionStorage.getItem("accessToken"),
      }
      console.log(myheaders);
      axios.get(`https://api.wakaw.live/v1/api/products/` + this.currentVideoObject.video_id,
        {
          headers:myheaders 
        }).then(response => {
          console.log(response);
          this.productDetailsList = response.data.data.product_ids;
          this.vendorUrl = response.data.data.product_ids[0].vendor_url;
          this.brandname = response.data.data.product_ids[0].brand_name;
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
    fetchProductDetails()
    {
      console.log('hola');
      this.startLoader();
      const myheaders = {
        'deviceId':sessionStorage.getItem('deviceId'),
        'userId':sessionStorage.getItem('userId'),
        'accessToken':sessionStorage.getItem("accessToken"),
      }
      console.log(myheaders);
      axios.get(`https://api.wakaw.live/v1/api/products/` + this.currentVideoObject.video_id,
        {
          headers:myheaders 
        }).then(response => {
          this.stopLoader();
          console.log(response);
          this.productDetailsList = response.data.data.product_ids;
          this.displayMode = 2;
          this.vendorUrl = response.data.data.product_ids[0].vendor_url;
        })
        .catch(e => {
          this.stopLoader();
          this.errors.push(e)
        })
    },
  playTheVideo()
  {
    this.isVideoMuted = false;
    var vid = document.getElementById("video-elem");
    vid.muted = false;
  },
  pauseTheVideo()
  {
    this.isVideoMuted = true;
    var vid = document.getElementById("video-elem");
    vid.muted = true;
  },
  fullScreenVideo()
  {
    var elem = document.getElementById("video-elem");
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { 
      elem.msRequestFullscreen();
    }
  },
  playVideo(index,i)
  {
    console.log('hola');
    this.currentVideoObject = i;
    var video = document.getElementById('video-elem');
    var source = document.createElement('source');

    source.setAttribute('src', i.videoUrls.MP4[0]);
    video.appendChild(source);
    video.load();
    video.play();
    console.log(source);
    try
    {
      this.getVideoAnalytics();
    }
    catch(e)
    {
      console.log(e)
    }
    this.isVideoLiked = false;
    this.videoUserName = i.username;
    this.setFollowStatus();
  },
  getBrandID()
  {
      console.log(this.$route.params.brand);
      if(this.$route.params.brand.length==0)
      {
        this.$router.push({ path: '/404notfound'}); 
      }
      else
      {
        console.log(this.$route.query.uid);
        console.log(this.$route.params.videoId);
        if(this.$route.query.uid && this.$route.params.videoId)
        {
          this.recordVideoShare(this.$route.query.uid,this.$route.params.videoId);
        } 
      }
      this.getVideoFeedForBrand(this.currPage,this.$route.params.brand,0);
  },
  getVideoFeedForBrand(currPage,brandID,pageChange)
  {
      console.log(brandID)
      this.startLoader();
        const myheaders = {
        'deviceId':sessionStorage.getItem('deviceId'),
        'userId':sessionStorage.getItem('userId'),
        'accessToken':sessionStorage.getItem("accessToken"),
      }
      console.log(myheaders);
      axios.get(`https://api.wakaw.live/v1/api/videofeeds?pageNumber=`+currPage+`&pageSize=10&brandId=`+brandID,
        {
          headers:myheaders 
        }).then(response => {
          this.stopLoader();
          console.log(response);
           
          this.videoDetailsList = response.data.data;

          if(this.videoDetailsList.length == 0 )
          {
            //this.$router.push({ path: '/404notfound'}); 
          }
          else
          {
            this.currPage = this.currPage + pageChange;
            var mydiv = document.getElementById('scrolldiv');
            mydiv.scrollTop = 0;
          }
          console.log(this.videoDetailsList);
          try{
            this.videoUserName = this.videoDetailsList[0].username;
          }
          catch(e)
          {
            console.log(e);
          }
          this.currentVideoObject = this.videoDetailsList[0];

          try{
            this.getVideoAnalytics();
          }
          catch(e)
          {
            console.log(e);
          }
          try{
            this.setFollowStatus();
          }
          catch(e)
          {
            console.log(e);
          }
          this.fetchProductDetailsInitial();
        })
        .catch(e => {
          this.errors.push(e)
        })
  },
  getVideoFeedForBrandPageChange(currPage,brandID,pageChange)
  {
      var myPage = currPage + pageChange;
      console.log(brandID)
      this.startLoader();
        const myheaders = {
        'deviceId':sessionStorage.getItem('deviceId'),
        'userId':sessionStorage.getItem('userId'),
        'accessToken':sessionStorage.getItem("accessToken"),
      }
      console.log(myheaders);
      axios.get(`https://api.wakaw.live/v1/api/videofeeds?pageNumber=`+myPage+`&pageSize=10&brandId=`+brandID,
        {
          headers:myheaders 
        }).then(response => {
          this.stopLoader();
          console.log(response);

          if(response.data.data.length > 0 )
          {
            this.videoDetailsList = response.data.data;
            this.currPage = this.currPage + pageChange;
            var mydiv = document.getElementById('scrolldiv');
            mydiv.scrollTop = 0;
          }
          else
          {
              this.$confirm("No further videos found", "Error", "error")
              .then(r => {
                console.log(r);
                console.log("OK selected.");
              })
              .catch(() => {
                console.log("OK not selected.");
              });
          }
        })
        .catch(e => {
          this.stopLoader();
          this.errors.push(e)
        })
  },
  myEventHandler(e) {
    console.log(e)
    document.getElementById('sidebar').style.height = (document.getElementById('videobox').clientHeight)/1.0233 + 'px';
    // your code for handling resize...
  }
  }
}
</script>

<style scoped>
.mobile_view.icons{
  position: absolute; 
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.header{
    position: absolute;
    height: 55px;
    left: 0%;
    right: 0%;
    top: 0px;
    display:inline;
    background: #424242;
    box-sizing: border-box;
    
}
.feedTitle{

height: 24px;
margin: 0;
margin-left: -150px;
width:fit-content;
padding: 14px;
font-family: Roboto;
font-style: normal;
font-weight: 500;
font-size: 20px;
color:white;
align-items: center;
}
.vjs_video_578-dimensions {
    width: 500px !important;
    height: 576px !important;
}
::-webkit-scrollbar {
    width: 0px !important;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: transparent !important;
}
.container-fluid{
  padding: 0px;
}
.row{
  margin-left: 0;
  margin-right: 0;
}
.videoSection {
        position: relative;
        overflow: hidden;
    }

    video{
      width: 100%;
      /*max-height: 700px !important;*/
      height: 99vh;
      object-fit:cover !important;
      transform: scale(1.22, 1);
    }



@media (max-width: 580px) {

  .shoe_box{
    width:35%;
  }

}


@media (max-width: 389px) {
.mobile_view{
  width:100vw !important;
}
}
@media (min-height: 500px) and (max-width: 650px){
.mobile_view{
  width: 100vw !important;
}
video{
  transform: scale(1.39,1);
  width:100%;
}
}
@media (min-width: 650px){
.mobile_view{
  width: 390px !important;
  padding-bottom: 25px;
}
}
@media (min-width: 700px){
.mobile_view{
  padding-left: 0px !important;
}
}
.mobile_view{
  width: 389px;
  height: fit-content;
  /*padding-top:4px;*/
  padding-bottom: 9px;
  box-shadow: 0 8px 15px rgba(14, 14, 14, 0.175);
  margin: 0 auto;
      border: 1px solid #020310;
        padding-left: 1px;
    padding-right: 1px;
    /*padding-top: 3px;*/
    max-height: 100%;
}
.shoe_box {
    position: relative;
    z-index: 10;
    width: fit-content;
        max-width: 35%;
}
.video_box {
    position: absolute;
    top: 0;
    z-index: 1;
    width:100%
}
.videoSection img.expand{
      position: absolute;
    z-index: 111;
    /* left: 0px; */
    top: 65px;
    right: 9px;
    color: white;
    margin: 1%;
    font-size: 30px;
    cursor:pointer
}
div.viewcount
{
    position: absolute;
    z-index: 111;
    top: 2.1%;
    right: 36%;
    color: white;
    margin: 1%;
    font-size: 16px;
}
.videoSection i.fa-eye{
    position: absolute;
    z-index: 111;
    top: 2%;
    right: 43%;
    color: white;
    margin: 1%;
    font-size: 25px;
    cursor:pointer
}
.videoSection img.brandlogo{
        position: absolute;
    z-index: 111;
    top: 0px;
    right: 0%;
    color: white;
    margin: 1%;
    font-size: 30px;
    cursor:pointer
}
.videoSection img.wtvlogo{
    position: absolute;
    z-index: 111;
    top: 0px;
    left: 1%;
    color: white;
    margin: 1%;
    font-size: 30px;
    cursor:pointer
}
.videoSection img.mute{
      position: absolute;
    z-index: 111;
    /* left: 0px; */
    top: 120px;
    right: 8px;
    color: white;
    margin: 1%;
    font-size: 35px;
    cursor:pointer
}
.videoSection img.speak{
      position: absolute;
    z-index: 111;
    /* left: 0px; */
    top: 120px;
    right: 8px;
    color: white;
    margin: 1%;
    font-size: 35px;
    cursor:pointer
}
.videoSection img.camera{
      position: absolute;
    z-index: 111;
    /* left: 0px; */
    top:53%;
    right: 9px;
    color: white;
    margin: 1%;
    font-size: 39px;
    cursor:pointer
}
.videoSection img.like{
      position: absolute;
    z-index: 111;
    /* left: 0px; */
    top:71%;
    right: 9px;
    color: white;
    margin: 1%;
    font-size: 38px;
    cursor:pointer;
}
.videoSection img.gift{
    position: absolute;
    z-index: 111;
    /* left: 0px; */
    top:43%;
    right: 9px;
    color: white;
    margin: 1%;
    font-size: 38px;
    cursor:pointer;
}
img.playv{
  border-radius: 50%;
}
.videoSection img.share{
        position: absolute;
    z-index: 111;
    /* left: 0px; */
    top:80%;
    right: 9px;
    color: rgb(136, 133, 133);
    margin: 1%;
    font-size: 39px;
        cursor:pointer
}
.videoSection img.cart{
      position: absolute;
    z-index: 111;
    /* left: 0px; */
    top:62%;
    right: 9px;
    color: white;
    margin: 1%;
    font-size: 40px;
    cursor:pointer
}
.videoSection div.userprofile{
        position: absolute;
    z-index: 111;
    /* left: 0px; */
    top:12%;
    left: 0px;
    color: white;
    cursor:pointer;
    width: fit-content;
    padding-right:7px;
    background: black;
    opacity: 0.6;
    height: 46px;
    border-top-right-radius: 23px;
    border-bottom-right-radius: 23px;
    display: flex;
    align-items: center;
}
button:active{
  border:none;
}
.videoSection img.userprofileicon{
      border-radius: 50%;
      opacity: 1.0;
          top:12%;
        margin-left: 3px;
        margin-right: 3px;
}
.hh img.playv{
         position: absolute;
    z-index: 111;
    /* left: 0px; */
    color: white;
    margin: 33%;
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
 }

.navbar{
  padding-top:2px !important;
  padding-bottom: 2px !important;
}
.hh{
  margin:6px;
  border:0.5px solid #C0C0C0;
  border-radius: 3px;
}
.buy-btn{
  width:90%;
  background-color: #F43B55 !important;
  border-radius: 4px;
  color:white;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  border: none;
  margin-top:6px
}
.buy-btn:active{
  width:90%;
  background-color: #F43B55 !important;
  border-radius: 4px;
  color:white;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  border: none;
  margin-top:6px
}
.vodal, .vodal-mask{
  z-index: 200 !important;
}
.mymodal >>>.vodal-dialog{
  padding:0 !important;
}
.center-it{
  display: flex;
  justify-content: center;
  margin-left:-15px;
}
.sm-icons{
  padding-left: 5px;
  padding-right: 5px;
  cursor:pointer;
}
.disabledbutton {
    pointer-events: none;
}
button:hover{border:none !important;}

button:focus{border:none !important;}
.swal2-popup{
  max-width:280px !important;
}
</style>